<template>
  <div class="container">
    <pcPublicHeader ref="pcPublicHeaderComponent" />
    <div class="public-content">
      <div class="page-title-item">个人中心</div>
      <div class="user-container">
        <pcUserHeader pageKey="user" />
        <div class="user-info-item">
          <div class="setting img-cover" @click="handleShowSetting"><img src="../../assets/pc-images/setting-blue-icon.png" /></div>
          <div class="userinfo-item">
            <div class="header-item img-cover">
              <img v-if="userInfo.Header" :src="userInfo.Header" />
              <img v-else src="../../assets/images/default-header.png" />
            </div>
            <div class="info-item">
              <div class="nickname-item">
                <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
                <span v-else>{{ userInfo.Phone | phoneFormat }}</span>
                <div class="vip-icon img-cover">
                  <img v-if="userInfo.Icon" :src="userInfo.Icon" />
                </div>
              </div>
              <div class="flex-1"></div>
              <div class="id">ID：{{ userInfo.Id }}</div>
              <div v-if="userInfo.FzLevel >= 50" class="level">您当前账户状态为{{ userInfo.FName }} <span v-if="userInfo.FzLevel < 52">，建议升级分站</span></div>
            </div>
            <div class="btn-item">
              <router-link class="btn" :to="{ path: '/website' }">分站管理</router-link>
              <router-link class="btn blue" :to="{ path: '/vip' }">升级会员</router-link>
            </div>
          </div>
        </div>
        <div class="balance-item">
          <div class="money">当前余额<span>¥{{ Math.round(userInfo.Balance * 10000) / 10000 }}</span></div>
          <router-link v-if="userInfo.UserIsCz == 1" class="btn" :to="{path: '/balance'}">立即充值</router-link>
        </div>
      </div>
      <div v-if="userInfo.FzLevel >= 50" class="substation-container">
        <div class="count-item">
          <div class="item">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-jrdd-icon.png" /></div>
            <div class="detail">
              <div class="title">今日销售订单</div>
              <div class="value">{{ dataInfo.order_count || 0 }}</div>
            </div>
          </div>
          <div class="item">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-jrtc-icon.png" /></div>
            <div class="detail">
              <div class="title">今日提成</div>
              <div class="value">{{ dataInfo.day_tc || 0 }}</div>
            </div>
          </div>
          <div class="item yellow">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-jrcz-icon.png" /></div>
            <div class="detail">
              <div class="title">今日充值</div>
              <div class="value">{{ dataInfo.cz_money || 0 }}</div>
            </div>
          </div>
          <div class="item">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-jrzcrs-icon.png" /></div>
            <div class="detail">
              <div class="title">今日注册人数</div>
              <div class="value">{{ dataInfo.user_count || 0 }}</div>
            </div>
          </div>
          <div class="item">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-jrdj-icon.png" /></div>
            <div class="detail">
              <div class="title">今日分站搭建</div>
              <div class="value">{{ dataInfo.fz_count || 0 }}</div>
            </div>
          </div>
          <div class="item yellow">
            <div class="icon img-cover"><img src="../../assets/pc-images/user-nav-ljdd-icon.png" /></div>
            <div class="detail">
              <div class="title">今日销售金额</div>
              <div class="value">{{ dataInfo.order_money || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="echarts-item">
          <div class="nav-item">
            <div class="nav" :class="chartType == 'order_count' ? 'active' : ''" @click="handleChartTypeChange('order_count')">销售订单</div>
            <div class="nav" :class="chartType == 'user_count' ? 'active' : ''" @click="handleChartTypeChange('user_count')">注册客户</div>
            <div class="nav" :class="chartType == 'fz_count' ? 'active' : ''" @click="handleChartTypeChange('fz_count')">搭建分站</div>
            <div class="nav" :class="chartType == 'order_money' ? 'active' : ''" @click="handleChartTypeChange('order_money')">下单金额</div>
            <div class="nav" :class="chartType == 'cz_money' ? 'active' : ''" @click="handleChartTypeChange('cz_money')">充值金额</div>
            <div class="time-item">
              <div class="item" :class="chartTime == 30 ? 'active' : ''" @click="handleSetChartTime(30)">30日</div>
              <div class="item" :class="chartTime == 7 ? 'active' : ''" @click="handleSetChartTime(7)">周</div>
              <div class="item" :class="chartTime == 365 ? 'active' : ''" @click="handleSetChartTime(365)">年</div>
            </div>
          </div>
          <div class="chart-item" ref="myechart" id="myechart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcUserHeader from '@/components/pcUserHeader'

import echarts from 'echarts'

export default {
  name: "User",
  components: {
    pcPublicHeader,
    pcUserHeader
  },
  data() {
    return {
      dataInfo: {},
      chartData: {},
      chartTime: 30,
      chartType: 'order_count'
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    }
  },
  mounted() {
    if (this.userInfo.FzLevel >= 50) {
      this.handleGetData()
      this.handleGetChartData()
    }
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleShowSetting() {
      this.$refs.pcPublicHeaderComponent.handleShowSetting()
    },
    async handleGetData() {
      var res = await this.$API.substationsInfo.post({
        Switch: 1
      })
      this.dataInfo = res
    },
    handleSetChartTime(num) {
      this.chartTime = num
      this.handleGetChartData()
    },
    getTime(num) {
      var today = new Date()
      var startTime = today.getTime() - (num * 24 * 3600 * 1000)
      return {
        StartTime: this.$TOOL.dateFormat(startTime, 'yyyy-MM-dd'),
        EndTime: this.$TOOL.dateFormat(today, 'yyyy-MM-dd'),
      }
    },
    handleChartTypeChange(type) {
      this.chartType = type
      const { chartData } = this
      let xAxisData = []
      let yAxisData = [{ name: '', type: 'value' }]
      let seriesData = [{ name: '', data: [], type: 'line', lineStyle: { color: '#486df1', width: 2 }, smooth: true }]
      if (type == 'order_count') {
        seriesData[0].name = '销售订单'
        yAxisData[0].name = '订单数量'
      }
      if (type == 'user_count') {
        seriesData[0].name = '注册客户'
        yAxisData[0].name = '客户数量'
      }
      if (type == 'fz_count') {
        seriesData[0].name = '搭建分站'
        yAxisData[0].name = '分站数量'
      }
      if (type == 'order_money') {
        seriesData[0].name = '下单金额'
        yAxisData[0].name = '下单金额'
      }
      if (type == 'cz_money') {
        seriesData[0].name = '充值金额'
        yAxisData[0].name = '充值金额'
      }
      for (const key in chartData) {
        if (Object.hasOwnProperty.call(chartData, key)) {
          const item = chartData[key];
          xAxisData.push(key)
          seriesData[0].data.push(item[type])
        }
      }
      var chartOption = {
        grid: {
          top: '45px',
          left: '60px',
          bottom: '45px',
          right: '60px'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: yAxisData,
        series: seriesData
      }
      var myechart = echarts.init(this.$refs.myechart)
      myechart.setOption(chartOption)
    },
    async handleGetChartData() {
      var res = await this.$API.substationsInfo.post({
        Switch: 2,
        ...this.getTime(this.chartTime - 1)
      })
      this.chartData = res
      this.handleChartTypeChange('order_count')
    },
  }
};
</script>

<style lang="less" scoped>
.page-title-item {
  padding: 30px 0 24px;
  line-height: 46px;
  color: #3d3d42;
  font-size: 32px;
  font-weight: bold;
}
.user-container {
  background-color: #fafbff;
  border-radius: 8px;
  overflow: hidden;
  .user-info-item {
    position: relative;
    background-color: #fff;
    padding: 60px 30px 20px 30px;
    border-radius: 8px;
    .setting {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
      width: 24px;
      height: 24px;
    }
    .userinfo-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      .header-item {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
      }
      .info-item {
        flex: 1;
        padding: 10px 24px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .nickname-item {
          display: flex;
          align-items: center;
          color: #333;
          font-weight: bold;
          .vip-icon {
            margin-left: 8px;
            height: 20px;
          }
        }
        .flex-1 {
          flex: 1;
        }
        .id, .level {
          line-height: 24px;
          color: #9fafc1;
        }
        .level {
          margin-top: 6px;
        }
      }
      .btn-item {
        display: flex;
        align-items: center;
        .btn {
          cursor: pointer;
          margin: 0 30px;
          width: 175px;
          height: 46px;
          box-sizing: border-box;
          border: 1px solid #9fafc1;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #3d3d42;
          font-size: 14px;
          font-weight: bold;
          &.blue {
            border-width: 0;
            background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
            color: #fff;
          }
        }
      }
    }
  }
  .balance-item {
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .money {
      color: #3d3d42;
      font-size: 16px;
      span {
        margin-left: 80px;
        font-size: 22px;
        font-weight: bold;
      }
    }
    .btn {
      width: 153px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background-color: #fe346e;
      border-radius: 6px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.substation-container {
  background-color: #fafbff;
  padding: 20px 30px;
  border-radius: 8px;
  margin-top: 20px;
  .count-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      position: relative;
      width: 220px;
      height: 80px;
      background-color: #ecf1ff;
      border-radius: 8px;
      overflow: hidden;
      .icon {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 60px;
        height: 60px;
      }
      .detail {
        position: relative;
        padding: 16px 0 0 20px;
        .title {
          line-height: 18px;
          color: #71757d;
          font-size: 12px;
        }
        .value {
          margin-top: 4px;
          line-height: 24px;
          font-size: 22px;
          font-weight: bold;
          color: #3d3d42;
        }
      }
    }
  }
  .echarts-item {
    margin-top: 20px;
    .nav-item {
      width: 100%;
      height: 64px;
      border-radius: 8px 8px 0 0;
      background-color: #fafbff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .nav {
        cursor: pointer;
        width: 124px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 8px 0 0;
        margin-right: 30px;
        font-size: 16px;
        color: #3d3d42;
        &.active,
        &:hover {
          font-weight: bold;
          color: #6a5ffe;
          background-color: #fff;
          box-shadow: 4px -4px 12px 0 rgba(0, 0, 0, .06);
        }
      }
      .time-item {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .item {
          cursor: pointer;
          width: 70px;
          height: 30px;
          border: 1px solid #e9edf5;
          border-color: #e9edf5 transparent;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          color: #3d3d42;
          &:nth-child(1) {
            border-radius: 8px 0 0 8px;
          }
          &:nth-last-child(1) {
            border-radius: 0 8px 8px 0;
          }
          &.active {
            color: #6a5ffe;
            border-color: #6a5ffe;
            background-color: rgba(106, 95, 254, .12);
          }
        }
      }
    }
    .chart-item {
      background-color: #fff;
      width: 100%;
      height: 316px;
      border-radius: 0 0 8px 8px;
    }
  }
}
</style>
